import React, { useState, useEffect } from "react";
import { db } from "../Firebase";
import { collection, getDocs } from "firebase/firestore";
import Slider from "react-slick";
import { HashLink as Link } from "react-router-hash-link";
import { Oval } from "react-loader-spinner";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Spinner = () => {
  return (
    <div className="flex justify-center mx-auto ">
      <Oval
        height={80}
        width={80}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

const SlideBar = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  const slidesCollectionRef = collection(db, "slides");
  useEffect(() => {
    const getSlides = async () => {
      try {
        const data = await getDocs(slidesCollectionRef);
        setSlides(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching slides:", error);
        setLoading(false);
      }
    };
    getSlides();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true
  };

  return (
    <div className="bg-white mt-10 w-full h-[-550px]">
      {loading ? (
        <Spinner />
      ) : (
        <Slider {...settings}>
          {slides.map((item) => (
            <div
              key={item.id}
              className="relative isolate mx-auto w-full h-full"
            >
              <div className='absolute w-full h-[550px] bg-black/50 opacity-80'></div>
              <img
                className="h-[550px] w-full object-cover "
                src={item.url}
                alt={item.slidesname}
              />

              

              <div className="top-20 absolute mx-auto left-0 right-0 flex-1 w-full max-w-2xl md:max-w-4xl text-center items-center justify-center lg:py-32">
                <h2 className="text-2xl md:text-4xl font-bold tracking-tight text-white">
                  {item.heading}
                </h2>
                <p className="mt-6 text-md md:text-xl text-center leading-2 text-gray-200 font-semibold">
                  {item.subheading}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link
                    to="#about"
                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Get started
                  </Link>
                  <Link
                    to="#about"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Learn more <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default SlideBar;
