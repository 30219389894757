import React, { useEffect, useState } from "react"
import { Tab } from "@headlessui/react";
import { db } from "../Firebase";
import { collection, addDoc, getDocs, doc, deleteDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import {AiFillDelete, AiOutlineEdit} from "react-icons/ai";

import 'react-toastify/dist/ReactToastify.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const priceTabs = [{ name: "Create" }, { name: "Preview" }];

const TableAdd = () => {
    const [prices, setPrices ] = useState([]);
  const [heading, setHeading ] = useState("");
  const [description, setDescription ] = useState("");

  const createPrice = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "price"), {
        heading,
        description,
      });
      setPrices(prices);
      toast.success("Succesfully added")
    } catch (error) {
      console.log(error.message);
    }
    return;
  };

  const priceCollectionRef = collection(db, "price");
  useEffect(() => {
   const getPrices = async () => {
    const data = await getDocs(priceCollectionRef);
    setPrices(data.docs.map((doc) =>({...doc.data(), id: doc.id })))
   }
   getPrices();
  }, []);

  const handleDelete = async (id) => {
    const priceDoc = doc(db, "price", id);
    await deleteDoc(priceDoc);
    setPrices(prices.filter((price) => price.id !== id));
    toast.success("Delete successful")
  };

  return (
    <div
      className="h-screen px-8 w-full bg-slate-200 max-w-7xl mx-auto justify-center overflow-y-scroll"
      id="price"
    >
      <div className="mx-auto text-center border-b-2 border-slate-300">
        <h2 className="text-xl font-bold text-gray-900 p-6">Price Catalogue</h2>
      </div>
      <Tab.Group>
        <Tab.List className="-mb-px flex space-x-8 px-1">
          {priceTabs.map((priceTab) => (
            <Tab
              key={priceTab.name}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "text-blue-600 border-blue-600 outline-none"
                    : "text-gray-900 border-transparent",
                  "flex whitespace-nowrap border-b-2 py-4 px-0 space-x-3 text-base font-medium"
                )
              }
            >
              {priceTab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div className="mt-8 max-w-4xl">
              <form onSubmit={createPrice} method="submit">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="heading"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Table Heading
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="heading"
                      id="heading"
                      rows={2}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setHeading(e.target.value)}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="description"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Table Body
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="description"
                      id="description"
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-10">
                  <button
                    type="submit"
                    className="block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </Tab.Panel>
          <Tab.Panel>
                <div className="max-w-5xl items-center justify-center mx-auto">
                    <div className="grid grid-cols-3 items-centers p-4 md:px-16 py-12">
                        {prices.map((item) => ( 
                            <div key={item.id} className="relative">

                               <div className="border border-gray-900 p-8 bg-gray-600 text-gray-100 font-bold">
                               <p>{item.heading}</p>
                               </div>
                                <div className="border border-gray-900 p-8 h-[200px] bg-white">
                                <p>{item.description}</p>
                                 
                                </div>
                                <div className="flex justify-between items-center">
                                <div className="absolute -translate-y-10 p-3 rounded-full bg-gray-200 cursor-pointer " onClick={() => handleDelete(item.id)}>
                                   <AiFillDelete className="text-red-400" />
                                </div>
                                 <div className="absolute -translate-y-10 translate-x-12 p-3 rounded-full bg-gray-200 cursor-pointer">
                                  <AiOutlineEdit />
                                </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default TableAdd;
