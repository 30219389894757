import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs } from 'firebase/firestore';


const Partners = () => {
  const [ partners, setPartners] = useState([]);

  const partnersCollectionRef = collection(db, "partner");
  useEffect(() => {
   const getPartners = async () => {
    const data = await getDocs(partnersCollectionRef);
    setPartners(data.docs.map((doc) =>({...doc.data(), id: doc.id })))
   }
   getPartners();
 
  },[]);

  return (
    <div className="bg-white py-24 md:py-32 lg:px-8 w-full h-full" id='partner'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
         CUSTOMERS & PARTNERS
        </h2>
        <h1 className="-mt-4 text-center text-2xl text-slate-600">____</h1>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 md:grid-cols-5 items-center gap-x-8 gap-y-10 md:max-w-4xl ">
         {partners.map((item) => (
          <div key={item.id} >
             <img
            className="col-span-2 max-h-48 w-full object-contain lg:col-span-1"
            src={item.url}
            alt={item.ImageAlt}
            width={358}
            height={148}
          />
          </div>
         ))}
         
        </div>
      </div>
    </div>
  )
}

export default Partners;
