import React, { useState, useEffect } from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { storage, db } from "../Firebase";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { Tab } from "@headlessui/react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SlideAdd = () => {
  const [file, setFile] = useState(null);
  const [heading, setHeading] = useState("");
  const [subheading, setSubheading] = useState("");
  const [slides, setSlides] = useState([]);

  const [progress, setPregress] = useState(0);

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFiles(file);
  };

  const uploadFiles = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/slide${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPregress(progress);
      },
      (error) => console.log(error),

      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          addDoc(collection(db, "slides"), {
            heading,
            subheading,
            url,
            Timestamp: serverTimestamp(),
          });
          setHeading("");
          setSubheading("");
          setFile(0);
        });
      }
    );
  };

  const slidesCollectionRef = collection(db, "slides");
  useEffect(() => {
    const getSlides = async () => {
      const data = await getDocs(slidesCollectionRef);
      setSlides(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSlides();
  }, []);

  const deleteSlide = async (id) => {
    const slideDoc = doc(db, "slides", id);
    await deleteDoc(slideDoc);
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true
  };

  const slideTabs = [{ name: "Create" }, { name: "Preview" }];

  return (
    <div className="h-screen px-8 w-full bg-slate-200 max-w-7xl mx-auto justify-center overflow-y-scroll" id="slide">
      <div className="mx-auto text-center border-b-2 border-slate-300">
        <h2 className="text-xl font-bold text-gray-900 p-6">
        Slider 
        </h2>
      </div>
      <Tab.Group>
        <Tab.List className="-mb-px flex space-x-8 px-1">
          {slideTabs.map((slideTab) => (
            <Tab
              key={slideTab.name}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "text-blue-600 border-blue-600 outline-none"
                    : "text-gray-900 border-transparent",
                  "flex whitespace-nowrap border-b-2 py-4 px-0 space-x-3 text-base font-medium"
                )
              }
            >
              {slideTab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div className="mt-8 max-w-4xl">
              <form onSubmit={handleUpload}>
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 max-w-3xl">
                  <div className="col-span-full">
                    <label
                      htmlFor="photo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Photo
                    </label>
                    <div className="mt-2 flex items-center gap-x-3">
                      <UserCircleIcon
                        className="h-20 w-20 text-gray-300"
                        aria-hidden="false"
                      />
                      <input
                        type="file"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="heading"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Slide Heading
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        name="heading"
                        id="heading"
                        rows={2}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setHeading(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="subheading"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Slide Subheading
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        name="subheading"
                        id="subheading"
                        rows={2}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setSubheading(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <p>Upload {progress}%</p>
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-blue-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Tab.Panel>
          <Tab.Panel className="outline-none">
            <div className="px-10">
              <Slider {...settings} className="static">
                {slides.map((item) => (
                  <div key={item.id} className="max-w-7xl relative inline-block">
                    <div className="mt-10 overflow-hidden rounded-lg">
                      <img
                        src={item.url}
                        alt="solar panel"
                        className="w-full h-56 block object-cover object-center"
                      />
                    </div>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      {item.heading}
                    </h1>
                    <p className="mt-6 text-xl leading-8 text-gray-700">
                      {item.subheading}
                    </p>
                    <div
                      onClick={() => {
                        deleteSlide(item.id);
                      }}
                      className="flex items-center justify-center mt-4 py-3 gap-3 cursor-pointer max-w-xs rounded-md bg-red-200 mx-10 hover:bg-red-500"
                    >
                      <RiDeleteBin2Fill className="w-6 h-6 " />
                      <h1>Delete</h1>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default SlideAdd;
