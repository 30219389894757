import React, { useEffect, useState } from "react"
import { Tab } from "@headlessui/react";
import { db } from "../Firebase";
import { collection, addDoc, getDocs, doc, deleteDoc } from "firebase/firestore";
import {RiDeleteBin2Fill} from "react-icons/ri";




function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
 const aboutTabs = [{ name: "Create" }, { name: "Preview" }];


const AboutAdd = () => {
  const [about, setAbout] = useState([]);
  const [overview, setOverview] = useState();
  const [description, setDescription] = useState();
  const [abouts, setAbouts] = useState([]);



  const createAbout = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "about"), {
        overview,
        description,
      });
      setAbout(about);
    } catch (error) {
      console.log(error.message);
    }
    return;
  };

  const aboutsCollectionRef = collection(db, "about");
  useEffect(() => {
   const getAbouts = async () => {
    const data = await getDocs(aboutsCollectionRef);
    setAbouts(data.docs.map((doc) =>({...doc.data(), id: doc.id })))
   }
   getAbouts();
  }, []);

  const deleteAbout = async (id) => {
    const aboutDoc = doc(db, "about", id);
    await deleteDoc(aboutDoc);
  }

  return (
    <div className="h-screen px-8 w-full bg-slate-200 max-w-7xl mx-auto justify-center overflow-y-scroll" id="abouts">
    <div className="mx-auto text-center border-b-2 border-slate-300">
      <h2 className="text-xl font-bold text-gray-900 p-6">
     About
      </h2>
    </div>
    <Tab.Group>
      <Tab.List className="-mb-px flex space-x-8 px-1">
        {aboutTabs.map((aboutTab) => (
          <Tab
            key={aboutTab.name}
            className={({ selected }) =>
              classNames(
                selected
                  ? "text-blue-600 border-blue-600 outline-none"
                  : "text-gray-900 border-transparent",
                "flex whitespace-nowrap border-b-2 py-4 px-0 space-x-3 text-base font-medium"
              )
            }
          >
            {aboutTab.name}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
          <Tab.Panel className="outline-none">
        <div className="mt-8 max-w-4xl">
          <form onSubmit={createAbout} method="submit">
            <div className="sm:col-span-2">
              <label
                htmlFor="overview"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Overview
              </label>
              <div className="mt-2.5">
                <textarea
                  name="overview"
                  id="overview"
                  rows={2}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setOverview(e.target.value)}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="description"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Main description
              </label>
              <div className="mt-2.5">
                <textarea
                  name="description"
                  id="description"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="mt-10">
              <button
                type="submit"
                className="block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Publish
              </button>
            </div>
          </form>
        </div>
        </Tab.Panel>
        <Tab.Panel>
        <div className="mt-10 w-full px-10 max-7xl">
         {abouts.map((about) => {
          return <div key={about} >
          
             <h1 className="mt-2 text-xl font-bold tracking-tight text-gray-900">
           {about.overview}
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
           {about.description}
          </p>
          <div onClick={()=> {deleteAbout(about.id);}} className="flex items-center justify-center mt-4 py-3 gap-3 cursor-pointer max-w-xs rounded-md bg-red-200 mx-10 hover:bg-red-500">
          <RiDeleteBin2Fill className="w-6 h-6 " />
          <h1>Delete</h1>
          </div>
            </div>
         })}
        </div>
        </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default AboutAdd;
