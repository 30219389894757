import React from "react";
import { MdSnippetFolder, MdDesignServices, MdOutlineSupportAgent } from "react-icons/md";
import {FaHandHoldingUsd, FaChalkboardTeacher} from "react-icons/fa";
// import { TfiLineDashed } from "react-icons/tfi";
import {GiAutoRepair} from "react-icons/gi";

const processes = [
  {
    icon: <MdSnippetFolder size={120}/>,
    name: "Sourcing"
  },
  {
    icon: <MdDesignServices size={120}/>,
    name: "Product Design"
  },
  {
    icon: <MdOutlineSupportAgent size={120}/>,
    name: "Sales Support"
  },
  {
    icon: <FaHandHoldingUsd size={120}/>,
    name: "Flexible Payment"
  },
  {
    icon: < FaChalkboardTeacher size={120}/>,
    name: "training"
  },
  {
    icon: <GiAutoRepair size={120}/>,
    name: "Maintenance"
  }
]
const Process = () => {
  return (
    <div
      className="relative m-auto bg-slate-200 py-24 md:py-32"
      id="process"
    >
      <div className="mx-auto items-center justify-center max-w-2xl text-center">
        <h2 className="text-lg font-semibold leading-8 text-blue-400 ">
          OUR PROCESS
        </h2>
       <h1 className="-mt-4 text-2xl text-slate-600">____</h1>
      </div>
      <div>
        <div className="bg-slate-200 py-16 sm:py-24">
          <div className="m-auto left-0 right-0 items-center justify-center max-w-2xl md:max-w-7xl px-6 lg:px-8 pr-4 md:pr-0">
            <div className="grid grid-cols-2 gap-x-2 md:gap-x-8 gap-y-8 text-center lg:grid-cols-3">
              {processes.map((item) => (
                <div key={item.name} className="mx-auto flex max-w-xl w-full flex-col items-center justify-center gap-3 bg-white rounded-2xl px-6 pb-10">
                <div className="text-blue-500 hover:text-blue-800 transition ease-in-out delay-150 hover:scale-125 ">
                 {item.icon}
                </div>
                <div className="text-xl font-bold tracking-light text-red-950 -translate-y-4">
                  {item.name}
                </div>
              </div>
              ))}
              
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
