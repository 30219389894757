import React from "react";
// import { Link } from 'react-router-dom';
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialLinkedin,
  TiSocialInstagram,
} from "react-icons/ti";
import { MdPhoneCallback } from "react-icons/md";
import { ImOffice } from "react-icons/im";
import { Link } from "react-router-dom";

const Contact = () => {
  const today = new Date();
  return (
    <div
      className="relative isolate overflow-hidden bg-blue-900 py-16 sm:py-24 lg:py-32"
      id="contact"
    >
      <div className="mx-auto max-w-7xl flex flex-col md:flex-row md:justify-between px-3 space-y-6 md:space-y-0 py-16">
        <div className="flex flex-col text-gray-900 text-xs md:text-sm md:font-medium space-y-4">
          <div className="max-w-md text-white">
            <div className="border-b border-gray-400 divide-y-8 text-slate-300 text-lg font-semibold">
              <p className="py-2">Mission Statement</p>
            </div>
            <p className="text-md mt-4">
              "Our mission is to empower farmers with sustainable irrigation
              solutions that maximize agricultural productivity, conserve water
              resources, and improve livelihoods. We strive to revolutionize
              traditional farming practices by harnessing renewable energy and
              advanced technologies, ensuring reliable and efficient irrigation
              for enhanced crop yields and a more resilient agricultural sector.
              Through our innovative solutions, we are dedicated to fostering
              sustainable development, promoting environmental stewardship, and
              contributing to food security in farming communities."
            </p>
          </div>
          
        </div>

        <div className="flex flex-col text-gray-900 text-xs md:text-sm md:font-medium space-y-4">
          <div className="border-b border-gray-400 divide-y-8 text-slate-300 text-lg font-semibold">
            <p className="py-2">Working Hours</p>
          </div>
          <h1 className="text-lg fontbold text-gray-100">
            Monday - Friday: 8am - 5pm
          </h1>
          <h1 className="text-lg fontbold text-gray-100">
            Saturday - Sunday: CLOSED
          </h1>
        </div>

        <div className="flex flex-col text-xs md:text-sm md:font-medium space-y-4 pb-4">
          <div className="border-b border-gray-400 divide-y-8 text-slate-300 font-semibold">
            <p className="py-2 text-lg">Socials</p>
          </div>
          <div className="flex item-center gap-3 text-gray-200 text-2xl cursor-pointer">
          <Link to="https://web.facebook.com/spunvertek/" target="_blank"><TiSocialFacebook /></Link>
            <Link to="https://www.twitter.com/spunvertek/" target="_blank"><TiSocialTwitter /></Link>
            <Link to="https://www.linkedin.com/company/spunvertek/" target="_blank"><TiSocialLinkedin /></Link>
          </div>
          <div className="border-b border-gray-500 divide-y-8 text-slate-300 font-semibold">
            <p className="py-2 text-lg">Contacts</p>
          </div>
          <a href="https://campaigner.top" className="text-gray-200">
            spunvertek@gmail.com
          </a>
          <div className="items-center gap-x-6 gap-y-2">
            <div className="flex items-center gap-2 text-blue-500">
              <MdPhoneCallback />
              <p className="text-sm leading-6 text-gray-300">
                +2347056547622, +2348036085202
              </p>
            </div>
            <div className="flex items-center gap-2 text-blue-500">
              <ImOffice />
              <p className="text-sm max-w-sm text-gray-300">
                59B Abidjan Street, Wuse Zone 3, Federal Capital Territory,
                Abuja, Nigeria
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center text-md md:text-lg font-bold tracking-tight text-gray-300">
        <a href="/">Spunvertek &copy; {today.getFullYear()}</a>
        <Link to="/login">
          <h1 className="text-blue-800 cursor-pointer">admin</h1>
        </Link>
      </div>
      <div
        className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
        aria-hidden="true"
      >
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
    </div>
  );
};

export default Contact;
