import React, { useState, useEffect } from "react";
import { db } from "../Firebase";
import { collection, getDocs } from "firebase/firestore";

function GallerySkeleton() {
  return (
    <div
      className="bg-white border rounded-sm px-6 py-24 sm:py-32 lg:px-8 w-full h-full"
      id="gallery"
    >
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-center text-lg font-semibold leading-8 text-blue-400">
          GALLERY
        </h2>
        <h1 className="-mt-4 text-2xl text-slate-600">____</h1>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {/* Render skeleton loading elements */}
            <div className="bg-gray-200 h-56 w-full animate-pulse rounded-lg group-hover:opacity-75"></div>
            <div className="bg-gray-200 h-56 w-full animate-pulse rounded-lg group-hover:opacity-75"></div>
            <div className="bg-gray-200 h-56 w-full animate-pulse rounded-lg group-hover:opacity-75"></div>
            <div className="bg-gray-200 h-56 w-full animate-pulse rounded-lg group-hover:opacity-75"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Gallery = () => {
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(8);

  const galleriesCollectionRef = collection(db, "galleries");
  useEffect(() => {
    const getGalleries = async () => {
      const data = await getDocs(galleriesCollectionRef);
      setGalleries(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setIsLoading(false);
    };
    getGalleries();
  }, []);

  if (isLoading) {
    return <GallerySkeleton />;
  }

  const handleViewMore = () => {
    setVisibleCount(visibleCount + 6);
  };

  return (
    <div
      className="bg-white border rounded-sm px-6 py-24 sm:py-32 lg:px-8 w-full h-full"
      id="gallery"
    >
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-center text-lg font-semibold leading-8 text-blue-400 ">
          GALLERY
        </h2>
        <h1 className="-mt-4 text-2xl text-slate-600">____</h1>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {galleries.slice(0, visibleCount).map((item) => (
              <div key={item.id} className="group">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={item.url}
                    alt={item.imageAlt}
                    className="h-56 w-full object-cover object-center group-hover:opacity-75"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {visibleCount < galleries.length && (
        <div className="text-center mt-4">
          <div className="absolute w-full bg-gradient-to-b from-black"></div>
          <button
            onClick={handleViewMore}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            View More
          </button>
        </div>
      )}
    </div>
  );
};

export default Gallery;
