import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from "../Firebase";
import { 
    signInWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged, 
} from 'firebase/auth';



const AuthContext = createContext();


export function AuthContextProvider({children}) {
    const [user, setUser ] = useState({});

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logOut() {
        return signOut(auth);
    }


    useEffect(() =>{
        const unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
            setUser(currentUser);
        });
        return () =>{
            unsubscribe();
        }
    });

    return ( 
        <AuthContext.Provider value={{ logIn, logOut, user}}> 
                {children}
        </AuthContext.Provider> 
    )
};

export function UserAuth() {
    return useContext(AuthContext); 
};