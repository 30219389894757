import React, { useEffect, useState } from "react";
import { db } from "../Firebase";
import { collection, getDocs } from "firebase/firestore";
import { BiSolidTime } from "react-icons/bi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Project = () => {
  const [projects, setProjects] = useState([]);

  const projectsCollectionRef = collection(db, "projects");
  useEffect(() => {
    const getProjects = async () => {
      const data = await getDocs(projectsCollectionRef);
      setProjects(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getProjects();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true
  };

  return (
    <div
      className="mx-auto w-full items-center justify-center bg-slate-200 px-10 py-24 md:py-32 h-full gap-x-8 "
      id="project"
    >
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-center text-lg font-semibold leading-8 text-blue-400 ">
          OUR PROJECTS
        </h2>
        <h1 className="-mt-4 text-2xl text-slate-600">____</h1>
      </div>
      <Slider {...settings} className="text-gray-900">
        {projects.map((item) => (
          <div
            key={item.id}
            className="mx-auto left-0 right-0 max-w-full md:max-w-7xl w-full flex flex-col flex-1 justify-center px-0 md:px-32"
          >
            <div className="mt-10 overflow-hidden rounded-lg">
              <img
                src={item.url}
                alt="solar panel"
                className="w-full h-80 block "
              />
            </div>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {item.heading}
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
              {item.subheading}
            </p>
            <div className="bg-white w-full max-w-xl flex p-2 md:p-6 items-center rounded-lg justify-start gap-x-3 md:gap-x-6 my-8">
              <BiSolidTime className="w-10 h-10" />
              <p>{item.startdate}</p>
              <HiOutlineDotsHorizontal className="w-10" />
              <p>{item.enddate}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Project;
