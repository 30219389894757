import React, { useState, useEffect } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { Tab } from "@headlessui/react";
import { storage, db } from "../Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import {AiOutlineClose} from "react-icons/ai";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
 const galleryTabs = [{ name: "Create" }, { name: "Preview" }];


const GalleryAdd = () => {
  const [file, setFile] = useState(null);
  const [galleries, setGalleries] = useState([]);

  const [progress, setPregress] = useState(0);

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFiles(file);
  };

  const uploadFiles = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/files${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPregress(progress);
      },
      (error) => console.log(error),

      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          addDoc(collection(db, "galleries"), {
            url,
            Timestamp: serverTimestamp(),
          });
          setFile(0);
        });
      }
    );
  };

  const galleriesCollectionRef = collection(db, "galleries");
  useEffect(() => {
    const getGalleries = async () => {
      const data = await getDocs(galleriesCollectionRef);
      setGalleries(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getGalleries();
  }, []);

  const deleteGallery = async (id) => {
    const galleryDoc = doc(db, "galleries", id);
    await deleteDoc(galleryDoc);
  }
  return (
    <div className="h-screen px-8 w-full bg-slate-200 max-w-7xl mx-auto justify-center overflow-y-scroll" id="gallerys">
    <div className="mx-auto text-center border-b-2 border-slate-300">
      <h2 className="text-xl font-bold text-gray-900 p-6">
     Gallery
      </h2>
    </div>
    <Tab.Group>
      <Tab.List className="-mb-px flex space-x-8 px-1">
        {galleryTabs.map((galleryTab) => (
          <Tab
            key={galleryTab.name}
            className={({ selected }) =>
              classNames(
                selected
                  ? "text-blue-600 border-blue-600 outline-none"
                  : "text-gray-900 border-transparent",
                "flex whitespace-nowrap border-b-2 py-4 px-0 space-x-3 text-base font-medium"
              )
            }
          >
            {galleryTab.name}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
          <Tab.Panel className="outline-none">
        <div className="mt-8 max-w-4xl">
          <form onSubmit={handleUpload}>
            <div className="col-span-full">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Upload images for this section
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
                    >
                      <input id="file-upload" name="file-upload" type="file" />
                    </label>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 1MB
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <p>Upload {progress}%</p>
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Upload
              </button>
            </div>
          </form>
        </div>
        </Tab.Panel>
        <Tab.Panel>
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-4 lg:grid-cols-4 xl:gap-x-8">
          {galleries.map((item) => (
            <div key={item.id} className="group relative">
              <div className=" overflow-hidden rounded-md bg-gray-200 ">
                <img
                  src={item.url}
                  alt={item.imageAlt}
                  className="w-full h-40 object-cover object-center"
                />
              </div>
              <div className='absolute top-0 left-0 w-full h-40 hover:bg-black/80 opacity-0 hover:opacity-100 flex items-center justify-center whitespace-normal'>
                <AiOutlineClose onClick={() =>deleteGallery(item.id)} className='absolute top-4 right-4 bg-slate-500 text-gray-300  cursor-pointer' />
            </div>
            </div>
          ))}
        </div>
        </Tab.Panel>
        </Tab.Panels>
        </Tab.Group>
      </div>
  );
};

export default GalleryAdd;
