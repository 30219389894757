import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "./admin/Admin";
import SignIn from "./pages/SignIn";
import { AuthContextProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import ProtectedRoute from "./component/ProtectedRoute";

function App() {
  return (
    <div className="bg-slate-50 m-auto overflow-x-hidden">
      <Router>
        <AuthContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route path="login" element={<SignIn />} />
           
          </Routes>
        </AuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
