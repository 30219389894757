
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialLinkedin,
  TiSocialInstagram,
} from "react-icons/ti";
import { MdPhoneCallback } from "react-icons/md";
import {ImOffice} from "react-icons/im";
import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <div className="hidden md:flex justify-between items-center gap-x-6 overflow-hidden bg-slate-50 px-16 py-2.5">
     
      <div className="flex items-center gap-x-6 gap-y-2">
       <div className="flex items-center gap-2 text-blue-500">
       <MdPhoneCallback />
        <p className="text-sm leading-6 text-gray-900">
          +2347056547622, +2348036085202
        </p>
       </div>
       <div className="flex items-center gap-2 text-blue-500">
       <ImOffice />
        <p className="text-sm leading-6 text-gray-900">
        59B Abidjan Street, Wuse Zone 3, Federal Capital Territory, Abuja, Nigeria
        </p>
       </div>
      </div>
      <div className="flex rounded-full bg-gray-900 px-8 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
          <div className="flex item-center gap-3 text-gray-200 text-2xl cursor-pointer">
            <Link to="https://web.facebook.com/spunvertek/" target="_blank"><TiSocialFacebook /></Link>
            <Link to="https://www.twitter.com/spunvertek/" target="_blank"><TiSocialTwitter /></Link>
            <Link to="https://www.linkedin.com/company/spunvertek/" target="_blank"><TiSocialLinkedin /></Link>
            
          </div>
        </div>
    </div>
  );
}
