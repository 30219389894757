import React, { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs } from 'firebase/firestore'; // Assuming you're using Firebase for the database
import { Oval } from "react-loader-spinner"

const Spinner = () => {
  return (
    <div className="flex justify-center mx-auto ">
      <Oval
        height={80}
        width={80}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};


const Team = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const teamsCollectionRef = collection(db, "team"); 

  useEffect(() => {
    const getTeams = async () => {
      try {
        const data = await getDocs(teamsCollectionRef);
        setTeams(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching teams:", error);
        setLoading(false);
      }
    };
    getTeams();
  }, []);

  return (
    <div className='bg-slate-900 px-2 md:px-6 py-24 md:py-32 lg:px-8 w-full h-full' id="team">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-center text-lg font-semibold leading-8 text-blue-400 ">OUR TEAM</h2>
        <h1 className="-mt-4 text-2xl text-slate-200">____</h1>
      </div>
      <div className="">
        <div className="mx-auto max-w-4xl w-full px-2 py-12 md:py-16 md:px-6 sm:py-24 md:max-w-7xl lg:px-8">
          {loading ? (
            <Spinner /> // Display the loading spinner when loading is true
          ) : (
            <div className="grid grid-cols-2 gap-x-6 gap-y-10 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
              {teams.map((item, index) => (
                <div key={index} className="flex flex-col items-center justify-center bg-slate-700 rounded-lg px-2 md:px-4 py-8">
                  <img
                    src={item.url}
                    alt={item.imageAlt}
                    className="h-36 md:h-56 w-36 md:w-56 rounded-full "
                  />
                  <div className='w-full h-full flex flex-col items-center justify-center whitespace-normal'>
                    <h3 className="mt-4 text-sm md:text-lg text-center text-gray-200 font-semibold md:font-extrabold">{`${item.firstName} ${item.lastName}`}</h3>
                    <p className="mt-1 text-xs md:text-md font-medium text-center text-gray-400">{item.position}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Team;
