import React from "react";
import Headroom from "react-headroom";
import Banner from "../component/Banner";
import Navbar from "../component/Navbar";
import SlideBar from "../component/SlideBar";
import Process from "./Process";
import Gallery from "./Gallery";
import Partners from "./Partners";
import MessageUs from "./MessageUs";
import Contact from "../component/Contact";
import About from "./About";
import Team from "./Team";
import Project from "./Project";
import PriceTable from "./PriceTable";



const Home = () => {
  return (
    <div>
    <Banner />
    <Headroom>
        <Navbar />
    </Headroom>
    <SlideBar />
    <About />
    <Process />
    <Gallery /> 
    <Project />
    <Team />
    <Partners />
    <PriceTable />
    <MessageUs />
    <Contact />
    </div>
  );
};

export default Home;
