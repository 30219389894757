import React, { useEffect, useState} from "react";
import { db } from "../Firebase";
import { collection, getDocs, } from "firebase/firestore";

function GallerySkeleton() {
  return (
    <div
      className="bg-white border rounded-sm px-6 py-24 sm:py-32 lg:px-8 w-full h-full"
      id="gallery"
    >
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-center text-lg font-semibold leading-8 text-blue-400">
          Price Table
        </h2>
        <h1 className="-mt-4 text-2xl text-slate-600">____</h1>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
            <div className="border border-blue-300 shadow-2xl rounded-md p-4 max-w-sm w-full mx-auto ">
              <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-8 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border border-blue-300 shadow-2xl rounded-md p-4 max-w-sm w-full mx-auto ">
              <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-8 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border border-blue-300 shadow-2xl rounded-md p-4 max-w-sm w-full mx-auto ">
              <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-8 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PriceTable = () => {
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const priceCollectionRef = collection(db, "price");
  useEffect(() => {
    const getPrices = async () => {
      const data = await getDocs(priceCollectionRef);
      setPrices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setIsLoading(false);
    };
    getPrices();
  }, []);

  if (isLoading) {
    return <GallerySkeleton />;
  }

  return (
    <div
    className="bg-white border rounded-sm py-24 sm:py-32 lg:px-8 w-full h-screen"
    id="price"
  >
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-center text-lg font-semibold leading-8 text-blue-400 ">
        PRICE PLAN
      </h2>
      <h1 className="-mt-4 text-2xl text-slate-600">____</h1>
    </div>
    <div className="max-w-7xl items-center justify-center mx-auto overflow-x-scroll">
                    <div className="flex flex-row mx-auto items-centers justify-center py-20">
                        {prices.map((item) => ( 
                            <div key={item.id} className="relative w-60">

                               <div className="border border-gray-900 p-2 md:p-8 h-20 bg-gray-600 text-gray-100 font-bold text-xs lg:text-md">
                               <p>{item.heading}</p>
                               </div>
                                <div className="border border-gray-900 h-40 p-1 md:p-8 bg-white overflow-y-scroll text-xs lg:text-md">
                                <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
    </div>
    
  )
};

export default PriceTable;
