import React, { useState, useEffect } from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { storage, db } from "../Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  addDoc,
  deleteDoc,
  collection,
  doc,
  serverTimestamp,
  getDocs,
} from "firebase/firestore";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Tab } from "@headlessui/react";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
 const teamTabs = [{ name: "Create" }, { name: "Preview" }];

const TeamAdd = () => {
  const [file, setFile] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [teams, setTeams] = useState([]);
  const [progress, setPregress] = useState(0);

  const handleUpload = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFiles(file);
  };

  const uploadFiles = (file) => {
    if (!file) return;
    const storageRef = ref(storage, `/team${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPregress(progress);
        toast.success("Upload successful");
      },
      (error) => console.log(error),

      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          addDoc(collection(db, "team"), {
            firstName,
            lastName,
            position,
            url,
            Timestamp: serverTimestamp(),
          });
          setFirstName("");
          setLastName("");
          setPosition("");
          setFile(0);
        });
      }
    );
  };

  const teamsCollectionRef = collection(db, "team");
  useEffect(() => {
    const getTeams = async () => {
      const data = await getDocs(teamsCollectionRef);
      setTeams(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getTeams();
  }, []);

  const deleteTeam = async (id) => {
    const TeamDoc = doc(db, "team", id);
    await deleteDoc(TeamDoc);
    toast.success("delete successful")
  };

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <div className="h-screen px-8 w-full bg-slate-200 max-w-7xl mx-auto justify-center overflow-y-scroll" id="team">
      <div className="mx-auto text-center border-b-2 border-slate-300">
        <h2 className="text-xl font-bold text-gray-900 p-6">
        Team
        </h2>
      </div>
      <Tab.Group>
        <Tab.List className="-mb-px flex space-x-8 px-1">
          {teamTabs.map((teamTab) => (
            <Tab
              key={teamTab.name}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "text-blue-600 border-blue-600 outline-none"
                    : "text-gray-900 border-transparent",
                  "flex whitespace-nowrap border-b-2 py-4 px-0 space-x-3 text-base font-medium"
                )
              }
            >
              {teamTab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="outline-none">
            <div className="mt-8 max-w-4xl">
          <form onSubmit={handleUpload}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 max-w-3xl">
              <div className="col-span-full">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  <UserCircleIcon
                    className="h-20 w-20 text-gray-300"
                    aria-hidden="false"
                  />
                  <input
                    type="file"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="position"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Position
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="position"
                    id="position"
                    autoComplete="position"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <p>Upload {progress}%</p>
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Upload
                </button>
              </div>
            </div>
          </form>
          <ToastContainer position="top-center" hideProgressBar={true} />
        </div>
        </Tab.Panel>
        <Tab.Panel>
        <div className="relative flex items-center group max-w-6xl">
          <MdChevronLeft
            onClick={slideLeft}
            size={40}
            className="absolute z-[10] left-0 bg-white rounded-full opacity-50 hover:opacity-100 hidden group-hover:block"
          />
          <div
            id={"slider"}
            className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative"
          >
            {teams.map((item) => (
              <div
                key={item.id}
                className="text-white w-[200px] relative inline-block p-2"
              >
                <img
                  className="w-full h-40 block"
                  src={item.url}
                  alt={item.imageAlt}
                />
              
                <div className="absolute top-0 left-0 w-full h-40 hover:bg-black/80 opacity-0 hover:opacity-100 items-center justify-center whitespace-normal">
                <p className="text-center text-xs">{`${item.firstName} ${item.lastName}`}</p>
                <p className="text-center text-xs">{item.position}</p>
                <p className="text-center text-xs">{item.qoute}</p>
                    <AiOutlineClose
                    onClick={() => deleteTeam(item.id)}
                    className="absolute top-4 right-4 text-gray-300"
                  />
                </div>
              </div>
            ))}
          </div>
          <MdChevronRight
            onClick={slideRight}
            size={40}
            className="absolute z-[10] right-0 bg-white rounded-full opacity-50 hover:opacity-100 hidden group-hover:block"
          />
        </div>
        </Tab.Panel>
        </Tab.Panels>
        </Tab.Group>
      </div>
  );
};

export default TeamAdd;
