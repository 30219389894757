import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { FaUsers, FaProductHunt, FaAngleDown } from "react-icons/fa";
import { HiOutlineShoppingBag } from "react-icons/hi";
import AboutAdd from "./AboutAdd";
import GalleryAdd from "./GalleryAdd";
import PartnerAdd from "./PartnerAdd";
import TeamAdd from "./TeamAdd";
import ProjectAdd from "./ProjectAdd";
import { UserAuth } from "../context/AuthContext";
import SlideAdd from "./SlideAdd";
import TableAdd from "./TableAdd";

const Admin = () => {
  const { user, logOut } = UserAuth();
  let navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  const handleLogOut = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="bg-slate-100">
        <main>
          <div className="flex mx-auto">
            <div className="w-28 bg-white px-4 fixed h-screen ">
              <h1 className="text-md font-extrabold tracking-tight text-gray-900 my-6 border-b-2 border-gray-200">
                Dashboard
              </h1>
              <div onClick={toggleMenu} className="flex gap-2 items-center bg-gray-100 hover:bg-gray-200 px-4 py-3 rounded-lg cursor-pointer">
              <h1 className="text-xs font-bold">Menu</h1>
                <FaAngleDown  size={20}/>
              </div>
             {showMenu && (
               <ul className="text-sm bg-gray-50 px-2 py-1 rounded-lg">
               <Link to="#slide">
                 <li className="bg-gray-100 hover:bg-gray-200 p-2 my-3 rounded-lg cursor-pointer shadow">
                   Slider
                 </li>
               </Link>
               <Link to="#abouts">
                 <li className="bg-gray-100 hover:bg-gray-200 p-2 my-3 rounded-lg cursor-pointer shadow">
                   About
                 </li>
               </Link>
               <Link to="#gallerys">
                 <li className="bg-gray-100 hover:bg-gray-200 p-2 my-3 rounded-lg cursor-pointer shadow">
                   Gallery
                 </li>
               </Link>
               <Link to="#projects">
                 <li className="bg-gray-100 hover:bg-gray-200 p-2 my-3 rounded-lg cursor-pointer shadow">
                   Projects
                 </li>
               </Link>
               <Link to="#team">
                 <li className="bg-gray-100 hover:bg-gray-200 p-2 my-3 rounded-lg cursor-pointer shadow">
                   Team
                 </li>
               </Link>
               <Link to="#partners">
                 <li className="bg-gray-100 hover:bg-gray-200 p-2 my-3 rounded-lg cursor-pointer shadow">
                   Partner
                 </li>
               </Link>
             </ul>
             )}

              <Link to="#customers">
                <div className="bg-gray-100 hover:bg-gray-200 px-8 py-3 my-2 inline-block rounded-lg cursor-pointer">
                  <FaUsers size={20} />
                </div>
              </Link>
              <Link to="#products">
                <div className="bg-gray-100 hover:bg-gray-200 px-8 py-3 my-2 inline-block rounded-lg cursor-pointer">
                  <FaProductHunt size={20} />
                </div>
              </Link>
              <Link to="#orders">
                <div className="bg-gray-100 hover:bg-gray-200 px-8 py-3 my-2 inline-block rounded-lg cursor-pointer">
                  <HiOutlineShoppingBag size={20} />
                </div>
              </Link>
              <div className="absolute bottom-2">
              <button
                onClick={handleLogOut}
                className="bg-blue-600 hover:bg-gray-200 px-5 text-xs text-white font-extrabold py-3 my-4 inline-block rounded-md cursor-pointer"
              >
                Logout
              </button>
            </div>
            </div>
           
            <div className="w-full border-2 border-gray-100 ml-28 ">
              <SlideAdd />
              <AboutAdd />
              <GalleryAdd />
              <ProjectAdd />
              <PartnerAdd />
              <TeamAdd />
              <TableAdd />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Admin;
